/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1023px) {

	.loader {
		.logo {width: 150px; height: 57px;} 
	}

	.wrapper {width: 688px;} 

	.btn {
		height: 40px; line-height: 40px; font-size: 14px;
		padding: 0 12px; font-size: 13px;
		&.btn-primary {
			padding: 0 25px;  
			&.sm {padding: 0 20px; font-size: 13px;}    
		}
		&.btn-secondary {
			font-size: 14px; padding: 0 17px; 
		}
		&.btn-catalog {
			padding: 0 14px; width: 48%; font-size: 13px; margin-right: 5px; 
			span {
				line-height: 23px; text-align: left; 
			}
		}		
	}
	.btn-play {
		&.right {
			margin-left: -80px; margin-top: -80px;  
		}
	}
	.btn-post { 
		padding: 0 20px; 
	}
	.slick-dots { 
		li {
			margin: 0 3px !important;   
			button {width: 9px; height: 9px;}   
		}
	}

	header {
		/*.wrapper {height: 76px; margin-top: 30px;} */
		.wrapper {height: 70px; margin-top: 30px;} 
		/*.burger-menu {top: 22px; width: 36px; height: 29px; left: 40px;}    */
		.burger-menu {top: 18px; width: 36px; height: 29px; left: 40px;}    
		.logo {width: 139px; height: 52px;} 
		.right {
			.box-other {padding-top: 20px;}  
		} 
	}

	.banner-page {
		figure {height: 350px;} 
	}

	.homepage {  
		.wrapper {width: 668px;}   
		header, footer {
			.wrapper {width: 668px;}          
		} 	  	
		.btn-play {margin-top:0;}   
		.box-abs-md {bottom: 30px;}
		.btn {
			&.btn-primary {
				font-size: 13px; 
				&.sm {padding: 0 12px;}    
			}
		}
	} 
	.contentpage { 
		header {
			.wrapper {width: 668px;}  
		} 
		.middle {
			/*padding: 76px 0 0 0; */
			padding: 72px 0 0 0; 
			z-index: 8; 
		}
	}
	.contentpage2 { 
		.wrapper {width: 668px;} 
		header {
			.wrapper {width: 668px;} 
			&.full { 
				.wrapper {
					height: 76px; 
				}	
				.burger-menu {left: 0;} 
				.right {
					.box-other {
						padding-right: 0;
					}  
				} 
			}
		}	
	}

	.section-home {
		&.section-1 {}
		&.section-2 {margin-top: 0;}
		&.section-3 {
			margin-top: 0; 
			&.space {padding-bottom: 325px;}   
		}
		.row-box {
			&.styled-1 {
				height: 390px; 
				.wrapper {width: 668px; padding: 148px 0 0 0;}     
			}
			&.styled-2 {
				height: 308px; 
				.wrapper {width: 668px; padding: 30px 0 0 0;}      
			}
			&.styled-3 {
				height: 320px;    
				.wrapper {width: 668px; padding: 30px 0 0 0;}       
			}
		}	
		.row-text {			
			height: 325px; 
			.wrapper {padding-top: 70px;} 
			h3 {margin-bottom: 10px;}  
			.short-text {line-height: 24px; max-height: 75px;}     
			.left {width: 100%; padding: 0;}
			.right {
				width: 100%; padding: 30px 0 0 0; text-align: left;  
				.btn {margin: 0 15px 0 0;}
				.ic-home {display: none;}  
			} 
		}	
	} 
	.boxscroll-trigger {height: 175px;} 

	.boxlist-md {
		height: 170px;
		.box {
			figure {margin: 8px 0 0 36px; width: 80px;} 
			.link-abs {margin: 8px 0 0 36px; width: 80px;}
		}
	} 
	.boxlist-lg { 
		.box {
			figure {width: 126px; height: 67%; margin: 38px 0 0 15px;} 
			.link-abs {width: 126px; height: 67%; margin: 38px 0 0 15px;} 
			&:nth-child(2n+2) {
				figure {width: 135px;height: 64%;margin: 47px 0 0 52px;}  
				.link-abs {
					width: 135px;height: 64%;margin: 47px 0 0 52px;
				}
			} 
		}
	}
	.boxlist-sm {
		.box {
			figure {width: 80px; height: 86%; margin: 10px 0 0 22px;}
			.link-abs {
				width: 80px; height: 86%; margin: 10px 0 0 22px;
			}
		}
	}

	.burger-filter {
		display: block; height: 44px; line-height: 44px; margin: 0 0 0 18px; padding: 0 0 0 18px;
		font-size: 15px; 
		/*color: #888; */
		color: #19515f;  
		position: relative; cursor: pointer;     
		&:before {
			position:absolute; width: 14px; height: 100%; content: ""; left: 0; top: 0;   
			background: url('../images/material/ic-filter.png') no-repeat center center;  
		}
	}	
	.box-large {
		margin-bottom: 20px; 
		.col {
			&.text {
				padding-left: 25px;
			}
		}
		&.md {
			.col {
				&.figure {
					width: 42%;					
					.thumbSlide {
						.slide {width: 77px; height: 77px;}   
					}
				}
				&.text {
					width: 58%;  
				}
			}
		}
		&.event {
			padding: 30px 40px;  
		}
		.retailSlider { 
			figure {
				height: 286px; 
			}
		}
	}
	.breadcrumb {} 
	.row-main {
		&.act {z-index: 15;} 
		.side {
			position: fixed; width: 350px; background: #fff; right: -150px; z-index: -1; top: 0; 
			padding:124px 50px 50px 50px; height: 100%; overflow:hidden; overflow-y: auto; 
			opacity: 0;  
			@include boxSizing(border-box); 
			&:after {
				position:absolute; width: 100%; height: 25px; background: #fff; content: ""; 
				left: 0; bottom: 0; z-index: 2; 
			}
			.close-side {
				display: block;
				position: absolute;
		        width: 40px;
		        height: 40px;        
		        right: 40px; 
		        top: 115px;   
		        cursor: pointer;
		        z-index: 5;
		        span {
		            display: block; width: 24px; height: 2px; background: #000; position: absolute;
		            top: 50%; left: 50%; margin: -1px 0 0 -12px;
		            @include transform(rotate(45deg));  
		            &:last-child {
		                @include transform(rotate(-45deg));
		            }
		        }
			} 
		}
		.main-content { 
			width: 100%; padding: 0; position: relative; z-index: 6; 
			/*&:before {
				position:absolute; width: 100%; height: 100%; background: #fff; content: ""; 
				left: -40%; top: -30px; z-index: -3;
			}   
			&:after {
				position:absolute; width: 100%; height: 100%; background: #fff; content: ""; 
				right: -40%; top: -30px; z-index: -3; 
			} */  
			.main-filter { 
				h1 {font-size: 22px;}  
				form {float: left; width: auto;} 
				.burger-filter {float: right;} 
			}
			.top-mid {
				.left {width: 100%; margin-bottom: 12px;}    
				.right {width: 100%;} 
				h1.left {width: auto;}
			}
			.ribbon {
				&.relative {
					&.mg-left {float: right;}  
				}
			}
			&.mc-right {width: 100%; padding: 0;} 
		}
		.article {
			p {
				&.med {font-size: 15px;} 
			}
			h3 {font-size: 18px; line-height: 30px;}   
		}

		&.rm-2 { 
			.wrapper {
				width: 668px; 
			}
		}	
		&.rm-event {
			.wrapper {
				width: auto; margin: 0 15px; 
			}
		}	
	}

	.tag-small {
		&.v2 {
			left: 0; margin-left: 0; 
		}
	}
	.nav-sub {
		ul {
			li {padding: 0 5px;} 
		} 
	}

	.box-cate-item {
		.item {
			.cl {
				font-size: 13px; 
				&.ico {}  
				&.txt {margin-left: 35px;} 
			}
		}
	}
	.box-address {
		.row {
			.cl {
				font-size: 15px;
				&:nth-child(1){width: 32%;} 
			} 
		}
		&.v2 {
			padding: 20px 15px; 
			.row  {
				padding: 0; 
				&:before {
					display:none;  
				}
				.cl {
					&:nth-child(1) {width: 32%;} 
					&:nth-child(2) {width: 68%;}  
				}
			}
		}
	}
	.row-catalog {}   
	.text-listing {
		padding: 35px 35px; 
		.row {margin: 0 0 20px 0;} 
	} 

	.std {
		width: 100%; 
		.col {margin-right: 15px;} 
		.left {
			&.fish {margin-left: 20px;}
		}
		.right {margin-left: 20px;} 
	}

	.table-std {
		table {
			thead {
				th {
					font-size: 13px; padding: 18px 7px;
					&:first-child {padding-left:12px;} 
					&:last-child {padding-left:12px;} 
				} 
			}
			tbody {
				td {
					font-size: 13px; padding: 18px 7px;
					&:first-child {
						padding-left:12px;
						&:before {width:12px;}
					} 
					&:last-child {
						padding-left:12px;
						&:after {width:12px;} 
					} 
				} 
			}
			tfoot {
				td {
					font-size: 13px; padding: 18px 7px;
					&:first-child {
						padding-left:12px;
						&:before {width:12px;}
					} 
					&:last-child {
						padding-left:12px;
						&:after {width:12px;} 
					} 
				} 
			}
		}
	}


	.frame-listing {
		.list-item {
			min-height: 70px;
			width: 47.5%; margin: 0 5% 5% 0;
			&:nth-child(3n+3) {margin-right: 5%;}  
			&:nth-child(2n+2) {margin-right: 0;}
		}  
		&.col-2 {
			.list-item {
				width: 48.5%; margin: 0 3% 0 0; 
				figure {height: 225px; margin-bottom: 16px;}   
			}
		}
		&.full {
			padding: 35px 30px;  
			.list-item {
				figure {
					height: 190px; width: 45%;   
				}
				.text {
					width: 55%; padding-left: 25px;   
				}
			}
		}
		&.other {
			.list-item {
				width: 46%; margin-right: 5%;  
				&:nth-child(2n+2) {margin-right: 0;} 
				&:nth-child(3n+3) {display: none;}
			}
		}
		&.marginbottom {
			.list-item {
				&:nth-child(3) {display: none;}  
			}
		}
		&.ot-2 {
			.list-item {
				.text {
					.date {padding-top: 30px;} 
				}
			}
		}
	}
	.retail-listing { 
		padding: 10px 0 0 0; 
		.list-item {
			padding: 0; margin-bottom: 4%; 
			.col {
				&.figure {height: 135px;} 
				&.text {
					padding: 0 0 0 20px;
					h4 {font-size: 16px;}  
				}
			}
		}
	}
	.row-about {
		.col {
			&.text {padding-left: 30px;} 
		}
	}

	.row-event {
		padding: 40px 40px;  
		.part-bottom {
			padding: 20px 25px; 
			.cl {
				&.left {width: 45%;}
				&.right {width: 55%;}
			}
			&.v2 {
				padding: 20px 25px; 
			}
		}
	}	
	.sitemap {
		.row {
			.col {
				width: 23.5%; margin: 0 2% 0 0; padding: 0 0 0 0;  
				&:last-child {padding-right:0; margin-right: 0;}  
			}
		}
	}

	.part-vote {
	    input.btn.btn-primary.vote {
	        border: 0; padding: 0 25px 0 50px; 
	        background-position: 20px 50%;
	        &.disabled {
	        	background-position: 20px 50%;	  
	        }
	    }
	}

	.form-container {  
		width: 100%; padding: 30px; 
	}
	.form-std {
		.input_file {
			span {
				&.filled {
					&:after {width:97px;}  
				}
			}
		}
	}

	.select2-dropdown.largedrop {}      

	.popup-menu {
		.nav-mob {
			ul {
				li {
					a {font-size: 18px; padding: 14px 0;}   
				}
			}
		}
	}

	.overlay-side {
		position: fixed; width: 100%; background: rgba(0,0,0, 0.5); left: 0; top: 76px; z-index: 15;  
		height: 100%;
	}

	footer {		
		.foot-top {
			.nav-foot { 
				ul {
					li {
						a {font-size: 14px; padding: 0 15px;}    
					}
				}
			}
		}		
		.foot-bottom { 
			.box-social { 
				a {
					width: 26px; height: 26px; margin: 0 5px;
					background-size: 100% !important;
				}
			}
		}
	}

	

	

}
